@import '../language.scss';

.lang-box {
  width: 150px;
  height: 185px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);

  img {
    max-height: 120px;
    margin: 0px 0px 15px 0px;
  }

  p {
    font-size: 20px;
    font-weight: $bold;
    color: $a-color;
  }
}

@media (max-width: 820px) {
  .lang-box {
    width: 100px;
    height: 130px;

    img {
      max-height: 60px;
    }

    p {
      font-size: 15px;
    }
  }
}
