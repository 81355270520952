@import '../../index.scss';

.languages {
  height: 670px;
  background-color: $other-background;
  text-align: center;

  .heading {
    font-size: 35px;
    color: #000000;
    font-weight: $lighter;
    padding: 50px 0px 40px 0px;
  }

  .row-1 {
    margin: 0px auto 100px auto;
    width: 85%;
    display: flex;
    justify-content: space-evenly;
  }
  .row-2 {
    margin: 100px auto 0px auto;
    width: 62%;
    display: flex;
    justify-content: space-evenly;
  }

  .svg-left {
    position: absolute;
    top: 930px;
    left: 66px;
  }

  .svg-right {
    position: absolute;
    top: 930px;
    right: 66px;
  }
}

@media (max-width: 820px) {
  .languages {
    margin-top: 110px;
    height: 570px;

    .heading {
      font-size: 25px;
    }

    .row-1 {
      width: 100%;
      margin-bottom: 0px;
    }

    .row-2 {
      width: 68%;
      margin: 60px auto 0px auto;
    }

    .svg-left,
    .svg-right {
      display: none;
    }
  }
}
