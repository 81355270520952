@import '../../index.scss';

.navbar {
  position: fixed;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #f0f0f0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;

  .logo {
    padding-left: 70px;

    .brand {
      font-weight: $bold;
      font-size: 20px;
      color: #000000;
    }
  }

  .nav-links {
    padding-right: 70px;

    ul {
      display: flex;

      li {
        list-style-type: none;
        padding: 0 15px;
        a {
          color: $body-font-color;
          &:hover {
            color: $a-color;
          }
        }
      }
    }

    .burger-icon {
      display: none;
    }
  }

  .nav-mobile {
    display: none;
  }
}

@media (max-width: 820px) {
  .navbar {
    .logo {
      padding-left: 30px;
    }
    .nav-links {
      padding-right: 30px;
      ul {
        display: none;
      }
      .burger-icon {
        display: block;
      }
    }

    .nav-mobile {
      display: block;
      position: absolute;
      top: 60px;
      right: 0px;
      width: 100%;
      height: 237px;
      background-color: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
      transform: scale(0);
      transform-origin: top;
      transition: 0.09s linear;
    }

    .nav-menu-open {
      transform: scale(1);
    }
  }

  .nav-res-links {
    text-align: center;

    ul {
      li {
        list-style-type: none;
        padding: 0 15px;
        margin: 20px;
        a {
          font-size: 18px;
          color: $body-font-color;
          &:hover {
            color: $a-color;
          }
        }
        .btn {
          margin: 21px;
        }
      }
    }
  }
}
