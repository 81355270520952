@import '../../index.scss';

.hero-section {
  padding-top: 60px;
  height: 570px;

  #background-svg {
    position: absolute;
    z-index: -1;
    top: 60px;
    max-width: 100vw;
  }

  .hero {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

    &-text {
      margin: 131px 0px 0px 100px;

      p {
        margin: 10px 0px;
      }

      .emphasis-1 {
        font-weight: $bold;
        font-size: 50px;
        color: #000000;
      }

      .emphasis-2 {
        font-weight: $lighter;
        font-size: 30px;
        color: #000000;
      }
    }

    #hero-svg {
      position: relative;
      right: 100px;
      top: 40px;
    }
  }
}

@media (max-width: 820px) {
  .hero-section {
    .hero {
      max-width: 100%;
      flex-direction: column;

      &-text {
        margin: 15px 0px;
        max-width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
      }

      &-img {
        height: 0px;

        #hero-svg {
          width: 90%;
          left: 15px;
          top: -50px;
        }
      }
    }
  }
}
